function OnlyNumber(e) {
	var tecla = window.event ? event.keyCode : e.which;
	if (tecla > 43 && tecla < 58) {
		if (tecla != 45 && tecla != 46 && tecla != 47) return true;
		else return false;
	} else {
		if (tecla == 8 || tecla == 0) return true;
		else return false;
	}
}

function MaskNumber(btn) {
	if (btn.value) {
		if (
			btn.value != ',' &&
			btn.value != ',,' &&
			btn.value != ',,,' &&
			btn.value != ',,,,' &&
			btn.value != ',,,,,'
		) {
			var valor = btn.value.replace(',', '.');
			var novoValor = parseFloat(valor).toFixed(2);
			var valor = novoValor.replace('.', ',');
			btn.value = valor;
		} else {
			btn.value = '0,00';
		}
	} else {
		btn.value = '0,00';
	}
}
